@import "../../variables.scss";

.checkout-page-container {
    .title {
        font-size: 1.5rem;
    }
    .payment-method-group {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 10px;
        @media (max-width: 575px) {
            justify-content: center;
        }
        .radio-btn-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;
            border: 1px solid $grey-1;
            border-radius: 5px;
            border-radius: $border-radius;
            transition: background-color 0.25s ease-in;

            @media (max-width: 575px) {
                padding: 5px 10px !important;
            }
            .radio-label {
                font-size: 1.25rem;
                font-weight: 500;
                cursor: pointer;
                @media (max-width: 575px) {
                    font-size: 1rem;
                }
            }

            &.selected {
                background-color: $secondary;
                border: none;
                .radio-label {
                    color: $white;
                }
            }

            .radio-btn {
                width: 25px;
                height: 25px;
                background-color: $light-blue-2;
                border: 1px solid $grey-1;
                border-radius: 50%;
                cursor: pointer;

                -moz-appearance: none; /* Firefox */
                -webkit-appearance: none; /* Safari and Chrome */
                appearance: none;

                @media (max-width: 575px) {
                    width: 20px;
                    height: 20px;
                }

                &:checked {
                    border: none;
                    background: url(../../../public/vectors/kasi-checkmark-circle-green.svg)
                        no-repeat center;
                }
            }
        }
    }
    .error-text {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $red;
    }

    .shipping-details-container {
        min-height: 20vh;
        .edit-btn {
            font-size: 1rem;
            background-color: $white;
            border: 1px solid $grey-5;
            border-radius: $border-radius;
        }
        .shipping-details {
            .shipping-name {
                font-weight: 500;
                text-transform: capitalize;
            }
            .shipping-address-phone {
                line-height: 186%;
                text-transform: capitalize;
            }
        }
    }

    .shipping-forms-wrapper {
        margin-top: 10%;
        .promo-code-field {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            .formfield-wrapper {
                margin-top: 0;
                flex-basis: 75%;
                .formfield-root {
                    border: 1px solid $primary;
                }
            }
            .promo-code-apply-btn {
                color: $white;
                font-weight: 400;
                background-color: $light-blue;
            }
        }
        .add-note-field {
            input {
                &::placeholder {
                    color: $green;
                }
            }
        }
        .delivery-title {
            text-transform: capitalize;
        }
        .delivery-fee {
            color: $light-blue-1;
            text-transform: capitalize;
        }

        .total-price {
            font-size: 1.5rem;
            color: $light-blue-1;
        }
        .total-title {
            font-size: 1.5rem;
            font-weight: 500;
            text-transform: capitalize;
        }
        .checkout-btn-wrapper {
            width: 25%;
            @media (max-width: 575px) {
                width: 50%;
            }
        }
    }
}
