@import "../../variables.scss";

// HowItWorksCard styles start

.how-it-works-card {
    height: 100%;
    border: 1px solid $grey-6;
    box-shadow: 0px 4px 14px rgba(197, 197, 197, 0.25);
    border-radius: $border-radius * 2;

    display: flex;
    flex-direction: column;

    .card-header {
        display: flex;
        justify-content: center;
        padding-top: 2.25rem;
        padding-bottom: 2rem;

        .header-icon-container {
            height: 8rem;
            width: 8rem;
            padding: 1rem;
            border-radius: 50%;
            background-color: $primary;
            .step-count {
                font-size: 3rem;
                font-weight: 500;
            }
        }
    }
    .card-body {
        background: $light-green-2;
        border-radius: $border-radius * 2;
        padding: 1.5rem 1rem 3rem 1rem;
        .card-title {
            height: 100px;
            font-size: 1.25rem;
            font-weight: 500;
            text-align: center;
            margin-bottom: 1rem;
        }
        .card-content {
            height: 50px;
            text-align: center;
            color: $dark-green;
        }
    }
}

// HowItWorksCard styles end

// BuyerBenefitCard styles start

.buyer-benefit-card {
    background: $white;
    box-shadow: 0px 4px 4px rgba(187, 187, 187, 0.2);
    border-radius: $border-radius * 2;
    padding: 2rem 0.75rem;

    height: 100%;
    display: flex;
    flex-direction: column;

    .card-header {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
        .header-image-wrapper {
            position: relative;
            width: 80%;
            padding-top: 40%;
            .header-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    .card-body {
        text-align: center;
        .card-title {
            font-size: 1.5rem;
            font-weight: 500;
            margin-bottom: 0.5rem;
        }
        .card-content {
            display: block;
        }
    }
}

// BuyerBenefitCard styles end

// CategoryCard styles start

.category-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .category-card-image-wrapper {
        width: 100%;
        height: 300px;
        .category-card-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .category-card-title {
        font-weight: 500;
        font-size: 1.25rem;
        text-align: center;
        color: $white;
        background-color: $dark-green;
    }
}

// CategoryCard styles end

// Product card tyles start

.product-card {
    width: 95%;
    margin-top: 25px;
    margin-bottom: 25px;
    .product-image-wrapper {
        position: relative;
        width: 100%;
        padding-top: 85%;
        border: 0.5px solid $grey-5;
        border-radius: $border-radius;
        cursor: pointer;

        .product-image {
            position: absolute;
            top: 0;
            left: 0;
            padding: 10%;
            height: 100%;
            width: 100%;
            object-fit: contain;
            transition: padding 0.25s ease-in-out;
            will-change: padding;

            &:hover {
                padding: 5%;
            }
        }
    }

    // Truncating title and subtitle for 2 lines
    .product-title,
    .product-subtitle {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
    }

    .product-title,
    .product-price {
        font-weight: 500;
        font-size: 20px;
    }
    .product-title {
        word-wrap: break-word;
    }
    .product-price {
        color: $green;
    }
    .product-subtitle {
        font-weight: 400;
        font-size: 14px;
        color: $grey-3;
    }
    .stock-status,
    .supplier-name,
    .discount-availability-text {
        font-weight: 500;
        font-size: 0.75rem;
    }
    .stock-status {
        line-height: 111.5%;
        color: $red;

        &.in-stock {
            color: $green-1;
        }
    }
    .supplier-name {
    }
    .discount-availability-text {
        color: $green-1;
    }
    .product-info {
        min-height: 10rem;
    }
    .view-product-btn {
        border: none;
        outline: none;
        border-radius: $border-radius;
        padding: 10px 32px;
        background: $primary;

        color: $dark-green;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
}

// Product card styles end

// PreviousOrderCard styles start

.previous-order-card {
    border: 1px solid $green;
    border-radius: $border-radius * 2;
    display: inline-block;
    padding: 20px 35px;
    margin-left: 25px;

    &:first-child {
        margin-left: 0;
    }

    @media screen and (min-width: 768px) {
        min-width: 800px;
        max-height: 350px;
    }

    @media (max-width: 767px) {
        min-width: 550px;
    }

    .order-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .order-label {
            display: flex;
            flex-wrap: wrap;
            font-size: 1.2rem;
            .order-number {
                color: $light-blue-1;
            }

            @media (max-width: 767px) {
                font-size: 1rem;
            }
        }
        .order-date {
            font-weight: 300;
            @media (max-width: 767px) {
                font-size: 0.875rem;
            }
        }
        .buy-again-btn {
            // extends bootstrap btn class in the ui
            background-color: $primary;
            color: $dark-green;
            border-radius: 0.125rem;
            padding: 7.5px 30px;
        }
    }
    .products-preview-bar {
        overflow-x: auto;
        display: flex;
        align-items: center;
        gap: 5px;
        margin: 2.5rem 0;

        @media (max-width: 767px) {
            margin: 1.5rem 0;
        }
        .product-preview-image {
            flex-shrink: 0;
            width: 100px;
            height: 100px;
            object-fit: cover;
            @media (max-width: 767px) {
                width: 65px;
                height: 65px;
            }
        }
        .truncated-product-count {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            padding: 0.5rem 1.5rem;
            border-radius: 1.25rem;
            background-color: $primary;
            font-size: 0.875rem;
            font-weight: 500;
        }
    }
    .order-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .products-count-label,
        .products-price-label {
            color: $grey-1;
            font-size: 1.25rem;
            font-weight: 500;

            @media (max-width: 767px) {
                font-size: 0.875rem;
            }
        }
        .products-count {
            color: $light-blue-1;
            font-size: 1.5rem;
            font-weight: 500;

            @media (max-width: 767px) {
                font-size: 1rem;
            }
        }
        .products-price {
            color: $green;
            font-size: 1.5rem;
            font-weight: 500;

            @media (max-width: 767px) {
                font-size: 1rem;
            }
        }
    }
}

// PreviousOrderCard styles end

//PreviousOrderProductCard styles start

.previous-order-product-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 225px;
    min-width: 200px;
    min-height: 100px;
    padding: 0.6rem;
    border-radius: 5px;
    border: 1px solid $grey-10;
    box-shadow: 2px 2px 4px 0px rgba(200, 200, 200, 0.25);

    .product-quantity {
        font-size: 1.125rem;
    }
}

//PreviousOrderProductCard styles end

//StoreLocationCard start

.store-location-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .store-title {
        font-size: 1.25rem;
        font-weight: 500;
        color: $blue-1;
        text-decoration: underline;
        text-transform: uppercase;
    }
    .store-address {
        font-size: 0.75rem;
        text-transform: uppercase;
    }
}

//StoreLocationCard end

// CartItemCard styles start

.cart-item-card {
    .item-select-checkbox {
        accent-color: $primary;
        height: 18px;
        width: 18px;
        flex-shrink: 0;
    }

    .cart-item-image-wrapper {
        position: relative;
        width: 100%;
        padding-top: 100%;
        .cart-item-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .qty-select-container {
        display: flex;
        align-items: center;
        gap: 5px;
        border-radius: $border-radius;
        font-size: 0.8rem;
        font-weight: 500;
        .qty-select {
            border: none;
            background-color: transparent;
        }
    }

    .stock-status {
        font-weight: 500;
        font-size: 12px;
        line-height: 111.5%;
        color: $red;

        &.in-stock {
            color: $green-1;
        }
    }

    .variant {
        font-weight: 500;
        font-size: 12px;
        line-height: 111.5%;
    }

    .cart-action-btn {
        font-size: 0.8rem;
        font-weight: 500;
        border: none;
        border-radius: 2px;
        padding: 5px 15px;
        background-color: rgba($color: $yellow-3, $alpha: 0.8);

        &:hover {
            background-color: rgba($color: $yellow-3, $alpha: 0.8);
        }
    }

    .unit-price {
        color: $red;
        text-decoration: line-through;
    }
}

// CartItemCard styles end

// MyAccountSectionCard styles start

.my-account-section-card {
    padding: 1.25rem 0.75rem;
    border-radius: $border-radius;
    border: 1px solid $grey-1;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 350px;

    @media (max-width: 575px) {
        width: 100%;
    }

    .section-image-wrapper {
        width: 30%;
        flex-shrink: 0;

        .section-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .section-title {
        font-weight: 500;
        margin-bottom: 0.5rem;
    }
    .section-description {
        font-size: 0.75rem;

        ul {
            padding-left: 1.5rem;
            margin-bottom: 0;
        }
    }
}

// MyAccountSectionCard styles end

// DealDetailsCard styles start

.deals-details-card {
    display: flex;
    align-items: center;
    .deals-image-wrapper {
        width: 15rem;
        min-width: 10rem;
        height: 15rem;
        .deals-image {
            width: 100%;
            height: 100%;
            border-radius: $border-radius * 2;
        }
    }
    .deals-details-wrapper {
        .deals-name {
            color: $dark-green;
            font-size: 1.5rem;
            font-weight: 500;
            text-transform: capitalize;
        }
        .valid-date {
            width: fit-content;
            font-size: 0.875rem;
            background-color: $primary;
            white-space: nowrap;
        }
    }
}

// DealDetailsCard styles end

// DealImageCard styles start

.deals-image-card {
    .deals-image-wrapper {
        height: 15rem;
        min-width: 15rem;
        .deals-image {
            width: 100%;
            height: 100%;
        }
    }
    .deals-details-wrapper {
        .deals-name {
            color: $dark-green;
            font-size: 1.5rem;
            font-weight: 500;
            text-transform: capitalize;
        }
        .valid-date {
            width: fit-content;
            color: $white;
            background-color: $primary;
            white-space: nowrap;
        }
    }
}

// DealImageCard styles end
