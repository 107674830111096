.product-images-card-skeleton {
    display: flex;
    gap: 40px;
    .product-image-thumbnails-wrapper-skeleton {
        position: relative;
        width: 55px;
        .product-image-thumbnails-container-skeleton {
            position: absolute;
            top: 0;
            left: 0;
            height: 85%;
            width: 100%;
            overflow: hidden;
            .product-image-thumbnails-scroll-container-skeleton {
                overflow: auto;
                width: 100%;
                height: 100%;
                box-sizing: content-box;
                padding-right: 17px;
                .product-image-thumbnail-skeleton {
                    display: block;
                    height: 55px;
                    width: 55px;
                    margin-bottom: 20px;
                }
            }
        }
    }
    .product-image-thumbnail-slider-wrapper-skeleton {
        flex-grow: 1;
        padding-top: 100%;
        position: relative;
        @media (min-width: 1200px) {
            padding-top: 85%;
        }
        .product-image-thumbnail-slider-container-skeleton {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding-top: inherit;
            .product-image-thumbnail-slider-skeleton {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.product-card-skeleton {
    width: 95%;
    margin-top: 25px;
    margin-bottom: 25px;
    .product-image-wrapper-skeleton {
        position: relative;
        width: 100%;
        padding-top: 85%;

        .product-image-skeleton {
            position: absolute;
            top: 0;
            left: 0;
            padding: 10%;
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
}

.table-skeleton {
    tr {
        td {
            &:nth-child(2) {
                width: 40%;
            }
        }
    }
}

.invoice-detail-skeleton {
    .checkmark-wrapper {
        display: block;
        width: 80px;
        height: 80px;
    }
}

.category-card-skeleton {
    .category-image-wrapper-skeleton {
        position: relative;
        width: 100%;
        padding-top: 100%;

        .category-image-skeleton {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
}
