@import "../../variables.scss";

// Formfield styling
.form-check-label {
    font-size: 0.75rem;
}

.formfield-wrapper {
    .formfield-root {
        position: relative;

        display: flex;
        align-items: center;

        border-radius: $border-radius;
        border: 1px solid $grey-5;
        padding: 0.5rem 0.875rem;
        transition: all 0.15s ease-in-out;
        will-change: auto;

        &.invalid {
            border-color: $red-3;
        }

        &:focus-within {
            border-color: $primary;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgba($color: $primary, $alpha: 0.25);
        }

        .formfield-label {
            position: absolute;
            top: 0;
            padding-left: 0.3125rem;
            padding-right: 0.3125rem;
            transform: translate(-2.5px, -50%);
            display: block;

            background-color: #fff;
            color: $secondary;

            font-style: normal;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 18px;
        }
        .formfield-input {
            border: none;
            outline: none;
            flex-grow: 1;
            width: 100%;

            &::placeholder {
                color: $grey-3;
            }
        }
        .password-toggle-btn {
            width: 25px;
            height: 25px;
            margin-left: 5px;
            border-radius: 50%;
            background-color: $grey-5;
            display: flex;
            justify-content: center;
            align-items: center;

            &.disabled {
                pointer-events: none;
            }
        }
        .formfield-end-btn {
            color: #fff;
            background-color: $secondary;
            border: none;
            border-radius: 2px;
            padding: 8px 13px;
            white-space: nowrap;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
        }
    }

    .formfield-error {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        padding-left: 1rem;
        padding-right: 1rem;
        color: $red;
    }
}

// Formfield - Select

.formfield-wrapper {
    margin-top: 1rem;
    .formfield-root.formfield-select {
        padding: 0;

        .formfield-input {
            position: relative;
            padding: 0.5rem 0.875rem;
            padding-right: 2rem;

            -moz-appearance: none; /* Firefox */
            -webkit-appearance: none; /* Safari and Chrome */
            appearance: none;

            background-color: transparent;
            background-image: url("../../../public/vectors/kasi-chevron-right-blue-bg.svg");
            background-size: 1.75rem;
            background-repeat: no-repeat;
            background-position-x: right;
            background-position-y: center;

            &:focus {
                background-image: url("../../../public/vectors/kasi-chevron-down-blue-bg.svg");
            }

            &.input-placeholder {
                color: $grey-3;
            }

            option {
                color: $black;
                background-color: $light-blue-2;
                font-weight: 500;

                &.active {
                    color: $primary;
                }
            }
        }
        .formfield-label {
            left: 0.875rem;
            transform: translate(-2.5px, -50%);
        }
    }
}

// Formfield - Select End

// Formfield - Phone

.formfield-wrapper {
    .formfield-root.formfield-phone {
        gap: 10px;
        .formfield-country-select {
            border: none;
            outline: 1px solid $primary;
            border-radius: 2px;
            background: transparent;
            width: 90px;
            padding: 0 1rem 0 0.25rem;

            font-size: 12px;
            line-height: 15px;

            -moz-appearance: none; /* Firefox */
            -webkit-appearance: none; /* Safari and Chrome */
            appearance: none;

            background-size: 1.25rem;
            background-repeat: no-repeat;
            background-position-x: right;
            background-position-y: center;
            option {
                background-color: $light-blue-2;
                .flag {
                    font-size: 16px;
                }
            }
        }

        .formfield-input {
            letter-spacing: 0.1em;
        }
    }
}

// Formfield - Phone end

//Formfield - OTP

.otp-input {
    width: 50px !important;
    height: 50px;
    font-size: 1.5rem;
    padding: 0.5rem 0.875rem;
    background-color: $light-blue-3;
    border-radius: $border-radius;
    border: none;
    transition: border 0.25s ease-in-out;
    will-change: auto;

    &.invalid {
        border: 1px solid $red;
    }
    &:focus-within {
        outline: 0;
    }
}

.otp-field-error {
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    padding-left: 1rem;
    padding-right: 1rem;
    color: $red;
    text-align: center;
}

//Formfield - OTP end

//Formfield - editable select

.react-select__dropdown-indicator {
    svg {
        visibility: hidden;
    }
}

//Formfield - editable select end

// Formfield styling end
