@import "../../../variables.scss";

.update-phone-number-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
    .content-container {
        position: relative;
        width: 100%;
        height: 85vh;
        margin: auto;
        background-color: $white;

        .close-btn {
            position: absolute;
            top: 12px;
            right: 12px;
            width: 32px;
            height: 32px;
            padding: 0;
            border-radius: 50%;

            border: none;
            background: none;
            .close-btn-img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}
