@import "../../variables.scss";

.footer-container {
    .footer-section-1 {
        .logo-wrapper {
            width: 150px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .store-logo-wrapper {
            .title {
                font-size: 1.5rem;
                font-weight: 600;
                color: $dark-green;
            }
        }
    }

    .footer-section-2 {
        .footer-link-containter {
            width: 60%;
            @media (max-width: 768px) {
                width: 100%;
            }

            .sub-title {
                font-size: 1.25rem;
            }
            .footer-links-wrapper {
                .footer-link {
                    color: $black;
                    text-decoration: underline;
                }
            }
        }
    }
}
