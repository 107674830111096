@import "../../variables.scss";

.home-banner-wrapper {
    background-color: $dark-green;
    min-width: 100vw;
    height: 70vh;
    @media (max-width: 991px) {
        height: 50vh;
    }
    @media (max-width: 767px) {
        height: 40vh;
    }
    @media (max-width: 575px) {
        height: 30vh;
    }
    .title {
        font-size: 3.5rem;
        font-weight: 500;
        line-height: 140.25%;
        text-transform: capitalize;
        @media (max-width: 767px) {
            font-size: 2rem;
        }
        @media (max-width: 575px) {
            font-size: 1rem;
        }
    }
    .tag-line {
        font-size: 1.5rem;
        @media (max-width: 767px) {
            font-size: 1rem;
        }
        @media (max-width: 575px) {
            font-size: 0.75rem;
        }
    }
    .banner-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
