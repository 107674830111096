@import "../../variables.scss";

.my-account-page-container {
    width: 85%;
    margin: auto;
    .title {
        font-size: 2rem;
        font-weight: 600;
    }

    .my-account-cards-wrapper {
        display: inline-flex;
        flex-wrap: wrap;
        gap: 40px;

        @media (max-width: 575px) {
            justify-content: center;
        }

        @media (max-width: 767px) {
            gap: 20px;
        }
    }

    .go-to-home-btn {
        margin: 10% auto;
        width: 25%;
        @media (max-width: 767px) {
            width: 50%;
        }
    }

    .logout-btn {
        color: $light-blue-1;
        background-color: $white;
        padding:0.875rem;
        border: 1px solid $grey-1;
        border-radius: $border-radius;
        outline: none;
        margin-top: 10%;
        width: 25%;
        @media (max-width: 767px) {
            width: 50%;
        }
    }
}
