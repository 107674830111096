@import "../../variables.scss";

.product-images-card {
    display: flex;
    gap: 40px;
    .product-image-thumbnails-wrapper {
        position: relative;
        width: 60px;
        .product-image-thumbnails-container {
            position: absolute;
            top: 0;
            left: 0;
            height: 85%;
            width: 100%;
            overflow: hidden;
            .product-image-thumbnails-scroll-container {
                overflow: auto;
                width: 100%;
                height: 100%;
                box-sizing: content-box;
                padding-right: 17px;
                padding-bottom: 5px;
                .product-image-thumbnail {
                    display: block;
                    height: 55px;
                    width: 55px;
                    object-fit: contain;
                    border-radius: $border-radius;
                    border: 1px solid $grey-4;
                    padding: 5px;
                    margin-bottom: 20px;
                    margin-left: auto;
                    margin-right: auto;
                    transform: scale(0.95);
                    transition: all 0.25s ease-in-out;
                    will-change: auto;
                    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.15);

                    &.active {
                        transform: scale(0.98);
                    }                    
                }
            }
        }
    }
    .product-image-thumbnail-slider-wrapper {
        flex-grow: 1;
        padding-top: 100%;
        position: relative;
        @media (min-width: 1200px) {
            padding-top: 85%;
        }
        .product-image-thumbnail-slider-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding-top: inherit;
            .product-image-thumbnail-slider {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 1px solid $secondary;
                border-radius: $border-radius;

                display: flex;
                flex-direction: column;
                justify-content: center;

                .product-image-wrapper {
                    position: relative;
                    width: 100%;
                    padding-top: 100%;
                    @media (min-width: 1200px) {
                        padding-top: 85%;
                    }
                    .product-image {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .slick-slider {
                    margin: 1rem;
                }
            }
        }
    }
}

.product-info-container {
    .container-title {
        display: flex;
        justify-content: space-between;

        @media (max-width: 767px) {
            flex-direction: column-reverse;
        }
        .product-title {
            font-size: 1.75rem;
            font-family: "Inter", sans-serif;
            line-height: 25px;
        }
        .product-price {
            font-size: 1.5rem;
            font-weight: 500;
            color: $green-1;
            line-height: 25px;

            @media (max-width: 767px) {
                font-size: 2rem;
            }
        }
    }

    .product-description {
        &.line-clamp {
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
        }

        font-size: 1rem;
        line-height: 24px;
    }
    .view-more {
        font-size: 1rem;
        line-height: 125%;
        color: $blue-1;
    }
    .stock-status {
        font-weight: 400;
        font-size: 1rem;
        color: $red;

        &.in-stock {
            color: $dark-green;
        }
    }
    .supplier-name {
        font-size: 1rem;
        font-weight: 500;
        span {
            color: $dark-green;
        }
    }
    .ratings-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;

        .rating,
        .rating-count {
            font-size: 1rem;
            text-align: center;
            color: $grey-2;
        }
    }
    .variants-container {
        .label,
        .variant-select {
            font-size: 1rem;
            line-height: 111.5%;
        }
        .variant-select {
            border: none;
            background-color: transparent;
            .formfield-wrapper {
                margin-top: 0;
                .formfield-root.formfield-select {
                    all: unset;
                    select {
                        appearance: auto !important;
                        background-image: unset !important;
                        all: unset;
                        width: 100%;
                    }
                }
            }
        }
    }
    .qty-container {
        display: inline-flex;
        align-items: center;
        gap: 5px;
        .label,
        .qty-select {
            font-size: 1rem;
            line-height: 111.5%;
        }
        .qty-select {
            border: none;
            background-color: transparent;
            .formfield-wrapper {
                margin-top: 0;
                .formfield-root.formfield-select {
                    all: unset;
                    select {
                        appearance: auto !important;
                        background-image: unset !important;
                        all: unset;
                        width: 100%;
                    }
                }
            }
        }
    }
    .add-to-cart-btn {
        border: none;
        outline: none;
        border-radius: $border-radius;
        padding: 10px 70px;
        background: $primary;

        color: $dark-green;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1rem;
    }

    .product-original-price {
        font-size: 1.25rem;
        font-weight: 500;
        color: $grey-1;
        text-decoration: line-through;
    }
    .discount-percentage {
        font-size: 0.875rem;
        font-weight: 400;
        color: $red;
    }
}
