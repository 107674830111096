@import "../../variables.scss";

.kasi-benefits-page {
    .page-title {
        color: $dark-green;
        font-size: 2rem;
        font-weight: 500;
    }
    .page-sub-title {
        color: $grey-11;
    }

    .deals-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 2rem 2.5rem;
    }
}
