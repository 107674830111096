@import "../../variables.scss";

.table-filter-wrapper {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    .table-filter-item {
        font-weight: 500;
        font-size: 1.25rem;

        &.active {
            color: $dark-green;
        }
    }
}
