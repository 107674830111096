// Themeing

$primary: #ffd26a;
$secondary: #4685ff;
$light: #fff;

$white: #fff;
$black: #000;
$grey: #3f3f3f;

$red: #e21c21;
$red-1: #ff2727;
$red-2: #ffbfbf;
$red-3: #eb5757;
$blue-1: #2f80ed;
$blue-2: #8ac0ff;
$light-blue: #59bbf4;
$light-blue-1: #4685ff;
$light-blue-2: #f8fcff;
$light-blue-3: #cadcff;
$light-blue-4: #f5f9ff;
$light-blue-5: #f9fdff;
$light-blue-6: #f1f8ff;
$light-blue-7: #0c5df5;
$green: #007e24;
$green-1: #219653;
$green-2: #245b3c;
$green-3: #6fcf97;
$light-green-1: #fcfffd;
$light-green-2: #f4fff9;
$dark-green: #054b54;
$yellow: #ffa500;
$yellow-1: #ffebad;
$yellow-2: #fff85640;
$yellow-3: #ffeea6;
$orange: #ff5f05;
$orange-1: #ef3a29;

$grey-1: #969696;
$grey-2: #4f4f4f;
$grey-3: #828282;
$grey-4: #bdbdbd;
$grey-5: #e0e0e0;
$grey-6: #f6f6f6;
$grey-7: #fbfbff;
$grey-8: #e1e6eb;
$grey-9: #f9fbff;
$grey-10: #eaeaea;
$grey-11: #333;

$purple: #a1a4d8;

$border-radius: 0.3125rem;
$border-radius-sm: 0.3125rem;
$border-radius-lg: 0.3125rem;

$body-bg: $white;

// Typography

$font-family-sans-serif: "Poppins", Arial, sans-serif;
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.5;
$text-muted: $grey-1;

$enable-responsive-font-sizes: true;

// Buttons

$btn-font-weight: 500;

$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 0.875rem;
