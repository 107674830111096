@import "../../variables.scss";

.chip {
    display: inline-block;
    border-radius: 5px;
    color: $grey;
    background-color: rgba($color: $grey, $alpha: 0.5);
    min-width: 100px;
    text-align: center;
    font-weight: 500;

    &.success {
        color: $green-2;
        background-color: $green-3;
    }
    &.danger {
        color: $red-1;
        background-color: $red-2;
    }
    &.warning {
        color: $yellow;
        background-color: $yellow-1;
    }
}