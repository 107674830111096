@import "../../variables.scss";

.auth-layout {
    height: 100vh;

    .content-area {
        height: 100%;

        .left-column,
        .right-column {
            display: flex;
            justify-content: center;
            height: 100%;
        }

        .left-column {
            padding: 0;
            background-color: $light-blue-2;
            img {
                width: 100%;
                object-fit: cover;
            }

            @media screen and (max-width: 767px) {
                display: none;
            }
        }

        .right-column {
            .language-drop-down-wrapper {
                img {
                    width: 15%;
                    margin-left: 0.5rem;
                    transform: rotate(-90deg);
                }
            }

            .page-content {
                height: 85vh;
                max-height: 85vh;
                overflow-y: scroll;
            }
        }
    }
}

.signin-welcome-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
        text-align: center;
        line-height: 1.5;
        text-transform: capitalize;
    }
}

.signin-page {
    .link-text {
        color: $light-blue-1;
        text-decoration: none;
    }
    .forget-password {
        font-size: 0.875rem;
    }
}

.forgot-password-note {
    color: $black;
    font-size: 0.75rem;

    .link-text {
        color: $light-blue-7;
        text-decoration: underline;
    }
}

.welcome-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    h1 {
        color: $green;
        line-height: 1.5;
    }
    .subtitle {
        color: $dark-green;
    }
    .btn-lg {
        font-size: 0.875rem;
    }
}
