@import "../../../variables.scss";

.changeable-number-field {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .decrement-btn {
        padding: 0;
        background: transparent;
        border: 1px solid $red;
        color: $red;
        border-radius: 50%;
        min-height: 25px;
        min-width: 25px;
        flex-shrink: 0;
    }
    .increment-btn {
        padding: 0;
        background: transparent;
        border: 1px solid $green;
        color: $green;
        border-radius: 50%;
        min-height: 25px;
        min-width: 25px;
        flex-shrink: 0;
    }
    .display-number {
        margin: 0.5rem;
        flex-shrink: 0;
        text-align: center;
        min-width: 1rem;
    }
}