@import "../../../variables.scss";

.viewable-field {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    .viewable-field-content {
        flex-grow: 1;
        .formfield-wrapper {
            min-width: unset;
            width: 100%;
        }
        .viewable-field-label {
            display: block;
            font-size: 1.25rem;
        }
        .formfield-wrapper {
            display: inline-block;
            min-width: 50%;
            margin-top: 0.75rem;
            .formfield-root {
                padding: 1rem 0.75rem;
                background-color: $light-blue-6;
                border: none;
                box-shadow: none;
                .formfield-input {
                    padding: 0;
                    background-color: transparent;
                    &::placeholder {
                        color: $grey-1;
                        text-overflow: ellipsis;
                    }
                    &:disabled {
                        color: $grey;
                    }
                }
                .formfield-country-select {
                    background-color: $white;
                    padding: 0.25rem 1rem;
                    outline-color: $grey-8;
                }
            }
        }
        .viewable-field-display-text {
            margin-top: 0.75rem;
            color: $grey;
        }
    }
    .viewable-field-actions-container {
        .viewable-field-action-btn {
            min-width: 175px;
            text-align: center;
            padding: 0.75rem;
            border-radius: $border-radius;
            border: 1px solid $grey-5;
            outline: none;
            background-color: transparent;
            transition: background-color 0.25s ease-in-out;
            will-change: background-color;

            &.savable {
                background-color: $primary;
            }
        }

        .viewable-field-end-btn {
            min-width: 200px;
            color: $white;
            background-color: $secondary;
            border: none;
            border-radius: $border-radius;
            padding: 1rem 2.5rem;
            white-space: nowrap;
            font-style: normal;
        }

        .bottom-margin {
            @media (min-width: 576px) {
                margin-bottom: 1.25rem;
            }
        }
    }
}

.viewable-editable-select {
    .formfield-root {
        padding: 0.5rem 0.875rem 0.5rem 0 !important;
    }
}
