@import "../../variables.scss";

.product-filter-container {
    border-bottom: 1px solid $grey-5;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 450px) {
        flex-direction: column;
    }

    .product-filter-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        @media (max-width: 767px) {
            justify-content: start;
        }

        @media (max-width: 450px) {
            > div {
                width: inherit;
            }
        }
    }

    .filter-menu-wrapper {
        background-color: $grey-7;
        border-radius: $border-radius;

        .filter-menu-title {
            text-transform: capitalize;
            white-space: nowrap;
        }

        &.sort-by-title {
            width: fit-content;
            margin-left: auto;
        }
    }
    .menu-drop-down {
        position: absolute;
        top: calc(100%);
        left: 0;
        width: 18rem;
        max-height: 25rem;
        overflow-y: scroll;
        background-color: $white;
        border-radius: $border-radius;
        filter: drop-shadow(2px 2px 4px rgba(179, 179, 179, 0.25));
        z-index: 1;

        &.right-align {
            right: 0;
            left: unset;

            @media (max-width: 450px) {
                left: 0;
                right: unset;
            }
        }

        .menu-option {
            &:hover {
                background-color: $grey-6;
            }
            .option-input {
                accent-color: $black;
                width: 1.5rem;
                height: 1.5rem;
            }

            .option-label {
                text-transform: capitalize;
            }

            &.order-value {
                .option-input {
                    -moz-appearance: none; /* Firefox */
                    -webkit-appearance: none; /* Safari and Chrome */
                    appearance: none;

                    width: 1.3em;
                    height: 1.3em;
                    background-color: $white;
                    border-radius: 50%;
                    vertical-align: middle;
                    border: 1px solid $grey-1;
                    outline: none;
                    cursor: pointer;
                    &:checked {
                        border: 1px solid $black;
                        background-color: transparent;
                        background-image: url("../../../public/vectors/kasi-checkmark-black.svg");
                        background-repeat: no-repeat;
                        background-position-x: center;
                        background-position-y: center;
                    }
                }
            }
        }

        .color-dot {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: $border-radius * 10;
        }

        .sort-by-option {
            &:hover {
                background-color: $grey-6;
            }
        }
    }
}

.min-max-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .min-max-input-wrapper {
        flex-grow: 1;
        .min-max-input {
            width: 100%;
            background-color: $grey-6;
            border-radius: $border-radius * 2;
            border: none;
            outline: none;
            appearance: none;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
        }
    }
    button {
        background-color: inherit;
        border: none;
    }
}
