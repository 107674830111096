@import "../../variables.scss";

// promotionCarousel style start
.promotion-container,
.banner-container {
    .slick-slide {
        padding: 0;
    }
    .slick-disabled {
        display: none !important;
    }
    .slick-arrow {
        display: none !important;
    }

    .banner-image-wrapper {
        min-width: 100vw;
        height: 40vh;

        &.full-height {
            height: 60vh;
            @media (max-width: 767px) {
                height: 40vh;
            }
            @media (max-width: 575px) {
                height: 30vh;
            }
        }

        .banner-image {
            width: 100%;
            height: 100%;
            object-fit: contain;

            &.image-bg {
                background-color: $yellow-2;
            }
        }

        .banner-fallback {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            color: $dark-green;
            height: 100%;
            background: rgb(251, 252, 244);
            background: linear-gradient(
                180deg,
                rgba(251, 252, 244, 1) 0%,
                rgba(232, 231, 200, 1) 100%
            );

            .valid-date {
                width: fit-content;
                background-color: $primary;
                white-space: nowrap;
            }
        }
    }
}

// promotionCarousel style end
