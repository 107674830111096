@import "../../../variables.scss";

.map-location-picker-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);

    display: flex;
    justify-content: center;
    align-items: center;

    .map-container {
        width: 100%;
        height: 85vh;
        border-radius: 20px;
        .close-btn {
            position: absolute;
            top: 12px;
            right: 12px;
            width: 32px;
            height: 32px;
            padding: 0;
            border-radius: 50%;

            border: none;
            background: none;
            .close-btn-img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .relocate-btn {
            position: absolute;
            bottom: 25%;
            right: 10px;
            width: 40px;
            height: 40px;
            padding: 0;

            border: none;
            background: none;
            .relocate-btn-img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .search-container {
            position: absolute;
            left: 50%;
            margin-top: 20px;
            transform: translateX(-50%);

            display: flex;
            align-items: center;
            border-radius: $border-radius;
            background-color: $white;
            padding: 5px;
            transition: all 0.15s ease-in-out;
            will-change: auto;

            &:focus-within {
                border-color: $secondary;
                outline: 0;
                box-shadow: 0 0 0 0.25rem rgba($color: $secondary, $alpha: 0.25);
            }

            .search-start-icon {
                height: 15px;
                width: 15px;
                margin: 0 10px 0 5px;
            }
            .search-input {
                border: none;
                outline: none;
                width: 250px;
            }
        }
        .marker-img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -100%);
        }
        .info-box-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -100%);
            background: $white;
            border: 1px solid $primary;
            margin-top: -63px;

            &::after {
                content: "";
                position: absolute;
                left: 50%;
                bottom: 0;
                height: 20px;
                width: 20px;
                background-color: $white;
                border: 1px solid $primary;
                transform: translate(-50%, 50%) rotate(45deg);
                border-top: none;
                border-left: none;
            }

            .info-box-content {
                position: relative;
                z-index: 1;
                display: flex;
                font-size: 1rem;
                gap: 20px;
                padding: 5px 20px;
                .home-img {
                    width: 30px;
                    object-fit: contain;
                }
                .street-address {
                    color: $light-blue-1;
                    &.placeholder {
                        width: 200px;
                    }
                }
                .city {
                    color: $dark-green;
                }
            }
        }
        .add-location-btn {
            position: absolute;
            bottom: 15%;
            left: 50%;
            width: 40%;
            min-width: 200px;
            transform: translate(-50%, 0);
        }
    }
}

.pac-container {
    z-index: 1100 !important;
}
