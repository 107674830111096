@import "../../variables.scss";

.landing-page {
    .section-title {
        color: $dark-green;
        font-size: 2.25rem;
        font-weight: 500;
    }

    .banner-section-1 {
        height: 100vh;
        @media (max-width: 767px) {
            height: 60vh;
        }
        @media (max-width: 575px) {
            height: 50vh;
        }
        .banner-img-wrapper {
            height: 60vh;
            @media (max-width: 767px) {
                height: 40vh;
            }
            @media (max-width: 575px) {
                height: 30vh;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }
        .banner-title {
            color: $orange;
            font-size: 2rem;
            font-weight: 600;
            line-height: 1.3;
            text-transform: uppercase;
            @media (max-width: 575px) {
                font-size: 1.5rem;
            }
        }
        .user-button {
            width: 250px;
            button {
                font-size: 1.5rem;
                @media (max-width: 575px) {
                    font-size: 1.15rem;
                }
            }
        }
    }

    .category-container {
        .slick-track{
            display: flex;
            justify-content: center;
            min-width: 90vw !important;
        }
        .slick-slide {
            padding: 0 1rem;
        }
        .slick-disabled {
            display: none !important;
        }
    }

    .how-it-works-container {
        display: inline-flex;
        flex-wrap: wrap;
        gap: 25px 25px;
        justify-content: center;
        .how-it-works-wrapper {
            width: 300px;
        }
    }

    .video-wrapper {
        position: relative;
        .video {
            width: 100%;
            height: 100vh;
            max-height: 500px;
        }

        .play-btn {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .banner-section-2 {
        background-color: $blue-2;
        .banner-title-wrapper {
            width: 40%;
            @media (max-width: 992px) {
                width: 70%;
            }
            .banner-title {
                font-size: 3rem;
                color: $white;
                line-height: 1.3;

                @media (max-width: 992px) {
                    font-size: 2rem;
                }

                @media (max-width: 576px) {
                    text-align: center;
                }
            }
        }

        .banner-img-wrapper {
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .register-banner {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        align-items: center;
        justify-content: center;
        background-color: $dark-green;
        padding: 4rem 3rem;
        .banner-title {
            font-size: 3rem;
            font-weight: 500;
            color: $white;
            text-align: center;

            @media (max-width: 576px) {
                font-size: 2rem;
            }
        }
        .btn-wrapper {
            button {
                padding: 0.5rem 2rem;
            }
        }
    }

    .help-btn-wrapper {
        display: flex;
        justify-content: center;
        button {
            padding: 0.5rem 4rem;
            background-color: $dark-green;
            border: none;
            outline: none;

            font-size: 1.5rem;
            font-weight: 500;
            color: $white;
        }
    }
}
