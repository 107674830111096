@import "../../variables.scss";

.pagination-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 575px) {
        flex-direction: column;
        align-items: flex-start;
    }

    .title{
        font-weight: 500;
    }
    
    .arrow-btn {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $grey-3;
        border-radius: 2px;
        background-color: $white;
        outline: none;
    }

    select {
        width: 45px;
        height: 30px;
        border: 1px solid $grey-3;
        border-radius: 2px;
    }
}
