@import "../../variables.scss";

.product-detail-page {
    .product-detail-container {
        display: flex;
        justify-content: space-between;

        @media (max-width: 767px) {
            flex-direction: column;
        }
    }
    .alternative-products-container {
        margin-bottom: 100px;
        .container-title {
            font-size: 1.5rem;
            font-weight: 500;
            color: $dark-green;
        }
        .alternative-products-wrapper {
            display: flex;
            flex-wrap: wrap;
        }
    }
    .offer-section {
        height: 40vh;
        background: url("../../../public/images/kasi-offer-bg.png") no-repeat center;
        background-size: cover;

        .offer-amount {
            font-size: 3rem;
            font-weight: 500;
            color: $primary;
        }
        .offer-title {
            font-size: 2rem;
            font-weight: 500;
            color: $white;
            text-transform: uppercase;
        }
        .btn-wrapper {
            button {
                font-size: 1rem;
                font-weight: 500;
                text-transform: uppercase;
            }
        }
    }
}
