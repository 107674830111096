@import "../../variables.scss";

.discount-drop-down-wrapper {
    .discount-title-wrapper {
        width: fit-content;
        border-radius: 2px;
        background-color: $light-blue-1;
        .discount-drop-down {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .discount-title {
                color: $white;
                font-weight: 500;
                font-size: 0.875rem;
                text-transform: capitalize;
            }
            .drop-down-btn {
                .active {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .drop-down-option-group {
        width: fit-content;
        border-radius: $border-radius;
        box-shadow:
            2px 2px 2px 0px rgba(194, 194, 194, 0.25),
            -2px 0px 2px 0px rgba(207, 207, 207, 0.25);
        .radio-btn {
            accent-color: $light-blue-1;
        }
        .radio-label {
            text-transform: capitalize;
        }
    }
}
