@import "../../variables.scss";

.container {
    .main-title {
        font-size: 2rem;
        font-weight: 600;
    }

    .sub-title {
        color: $orange-1;
        font-weight: 600;
    }
    .content,
    ul {
        font-size: 0.875rem;
    }
    .disclaimer-note {
        color: $yellow;
        font-weight: 600;
    }
}
