@import "../../variables.scss";

.variant-drop-down-wrapper {
    background-color: $grey-9;
    border: 1px solid $grey-5;
    .variant-drop-down {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .variant-title {
            font-weight: 500;
            font-size: 0.875rem;
            text-transform: capitalize;
        }
        .drop-down-btn {
            .active {
                transform: rotate(180deg);
            }
        }
    }
    .drop-down-option-group {
        .radio-btn {
            accent-color: $black;
        }
        .radio-label {
            font-size: 0.875rem;
            font-weight: 500;
            color: $grey;
            text-transform: capitalize;
        }
    }
}
