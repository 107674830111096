@import "../../../variables.scss";

.content-container {
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: $white;
    border: 1px solid $primary;
    border-radius: $border-radius;
    .alert-message {
        font-size: 0.75rem;
    }
}
