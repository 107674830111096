@import "../../variables.scss";

.contact-layout {
    height: 100vh;

    .content-area {
        height: 100%;

        .left-column,
        .right-column {
            display: flex;
            justify-content: center;
            height: 100%;
        }

        .left-column {
            padding: 0;
            background-color: $light-blue-2;
            img {
                width: 100%;
                object-fit: contain;
            }

            @media screen and (max-width: 767px) {
                display: none;
            }
        }

        .right-column {
            .language-drop-down-wrapper {
                img {
                    width: 15%;
                    margin-left: 0.5rem;
                    transform: rotate(-90deg);
                }
            }

            .page-content {
                height: 85vh;
                max-height: 85vh;
                overflow-y: scroll;
            }
        }
    }
}
.link-text {
    cursor: pointer;
    text-decoration: underline;
    color: #000;

    &:hover {
        color: #000;
    }
}