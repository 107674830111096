@import "../../variables.scss";

// profile edit page styles start

.profile-sub-page-container {
    width: 65%;
    margin: auto;

    @media (max-width: 767px) {
        width: 80%;
    }

    .action-btn {
        width: 100%;
        color: $white;
        background-color: $secondary;
        border: none;
        border-radius: $border-radius;
        padding: 1rem 2.5rem;
        white-space: nowrap;
        font-style: normal;
    }
}

// profile edit pages styles end

// profile orders & invoice list page styles start

.filter-section {
    border-bottom: 1px solid $grey-4;
}

.offer-section {
    height: 40vh;
    margin-top: 100px;
    background: url("../../../public/images/kasi-offer-bg.png") no-repeat center;
    background-size: cover;

    .offer-amount {
        font-size: 3rem;
        font-weight: 500;
        color: $primary;
    }
    .offer-title {
        font-size: 2rem;
        font-weight: 500;
        color: $white;
        text-transform: uppercase;
    }
    .btn-wrapper {
        button {
            font-size: 1rem;
            font-weight: 500;
            text-transform: uppercase;
        }
    }
}

// profile orders & invoice list page styles end

// profile order & invoice detail page styles start

.order-detail-page {
    width: 85%;
    margin: auto;

    @media (max-width: 767px) {
        width: 100%;
    }
    .order-number,
    .order-date {
        color: $blue-1;
    }

    .payment-success-status {
        display: flex;
        align-items: center;
        justify-content: center;
        .checkmark-wrapper {
            img {
                width: 80px;
                height: 80px;
            }
        }
    }

    .order-name {
        font-weight: 500;
    }

    .order-table-container {
        .table-responsive {
            scroll-behavior: smooth;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .align-right {
            text-align: right;
        }

        .table-header {
            border-top: 1px solid $black;
            border-bottom: 1px solid $black;
            th {
                font-weight: 500;
                white-space: nowrap;
            }
        }

        tbody {
            tr {
                font-size: 0.875rem;
                font-weight: 500;
                white-space: nowrap;
                &:first-child {
                    td {
                        padding-top: 2rem !important;
                    }
                }

                &:last-child {
                    td {
                        padding-bottom: 2rem !important;
                    }
                }

                td {
                    &.success {
                        color: $green;
                    }
                    &.danger {
                        color: $red;
                    }
                    &.warning {
                        color: $yellow;
                    }
                }
            }
        }

        .table-footer {
            border-top: 1px solid $black;
            border-bottom: 1px solid $black;

            tr {
                td {
                    color: $blue-1;
                    font-weight: 500;
                }
            }
        }
    }
}

.invoice-table-body {
    color: $grey;
}

.invoice-table-footer {
    font-size: 1.5rem;
}

// profile order & invoice detail page styles end
