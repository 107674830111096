@import "../../variables.scss";

.paginated-table {
    .table-responsive {
        scroll-behavior: smooth;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }
    thead {
        th {
            font-weight: 500;
            white-space: nowrap;

            &:nth-child(2) {
                width: 40%;
            }
        }
    }
    tbody {
        tr {
            font-weight: 500;
            white-space: nowrap;
            td {
                vertical-align: middle;
            }
        }
    }
}
