@import "../variables.scss";
/*
   * Navbar
   */
.navbar-wrapper {
    position: sticky;
    z-index: 100;
    top: 0;

    .navbar {
        .navbar-brand {
            .brand-logo-wrapper {
                position: relative;
                padding-top: 50%;
                width: 100%;
                .brand-logo {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .nav-link {
            color: $dark-green;
            font-weight: 500;
        }

        .navbar .navbar-toggler {
            top: 0.25rem;
            right: 1rem;
        }
        .navbar-search-container {
            display: flex;
            align-items: center;
            border-radius: $border-radius;
            background-color: $primary;
            padding: 5px;
            transition: all 0.15s ease-in-out;
            will-change: auto;

            &:focus-within {
                border-color: $secondary;
                outline: 0;
            }

            .navbar-search-start-icon {
                height: 20px;
                width: 20px;
                margin: 0 10px 0 5px;
            }
            .navbar-search-end-icon {
                height: 30px;
                width: 30px;
                margin: 0 5px 0 10px;
            }
            .navbar-search-input {
                border: none;
                outline: none;
                flex-grow: 1;
                background-color: $primary;

                &::placeholder {
                    color: $black;
                }
            }
        }

        .nav-btn-cart {
            height: 40px;
            width: 40px;
            background: transparent;

            border: none;
            outline: none;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .cart-text {
                text-align: center;
                font-size: 0.75rem;
            }

            .cart-img {
                flex-shrink: 0;
                position: relative;
                img {
                    height: 20px;
                    width: 20px;
                    object-fit: contain;
                }
                .cart-badge {
                    position: absolute;
                    top: 0;
                    right: 0;
                    transform: translate(42%, -15%);
                    border-radius: 1rem;
                    min-width: 15px;
                    line-height: 15px;

                    padding-left: 2px;
                    padding-right: 2px;

                    background-color: $red;
                    color: $white;

                    font-size: 10px;
                    text-align: center;
                }
            }
        }

        .my-account-nav {
            font-size: 0.75rem;
            font-weight: 500;
            color: $dark-green;
            .greeting {
                font-size: 0.7rem;
                font-weight: 400;
            }
        }
    }
}
