@import "../../variables.scss";

.cart-summary-page {
    .cart-items-container {
        border: 1px solid $primary;
        background: $light-green-1;
        border-radius: $border-radius;

        .divider {
            height: 1px;
            width: 100%;
            background: $grey-4;
        }
        .select-all-checkbox {
            height: 18px;
            width: 18px;
            accent-color: $green;
        }
    }
    .cart-actions-container {
        border: 1px solid $primary;
        border-radius: $border-radius;
        box-shadow: 0px 4px 4px rgba(206, 206, 206, 0.25);
        .shipping-details-container {
            display: flex;
            gap: 10px;
            font-weight: 500;
            font-size: 0.75rem;

            .checkmark-icon {
                height: 22px;
                width: 22px;
            }
        }
        .billing-details-containter {
            font-size: 0.875rem;
            .total,
            .delivery-fee {
                color: $light-blue-1;
            }
        }
    }
    .offer-section {
        &.top {
            background: $light-blue-5;
            .offer-title {
                color: $dark-green;
            }
        }
        height: 40vh;
        background: url("../../../public/images/kasi-offer-bg.png") no-repeat center;
        background-size: cover;

        .offer-amount {
            font-size: 3rem;
            font-weight: 500;
            color: $primary;
        }
        .offer-title {
            font-size: 2rem;
            font-weight: 500;
            color: $white;
            text-transform: uppercase;
        }
        .btn-wrapper {
            button {
                font-size: 1rem;
                font-weight: 500;
                text-transform: uppercase;
            }
        }
    }
}
