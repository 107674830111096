@import "./variables.scss";
@import "bootstrap/scss/bootstrap";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
    scrollbar-width: thin;
    scrollbar-color: #969696 transparent;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        width: 4px;
        height: 2px;
        border-radius: 1rem;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 1rem;
        background: #969696;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #828282;
    }
}
