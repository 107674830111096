@import "../../variables.scss";

.products-page {
    .page-content {
        .content-title {
            color: $dark-green;
        }
        .previous-order-wrapper {
            display: flex;
            align-items: center;
            .arrow-wrapper {
                min-width: 48px;
                button {
                    width: 100%;
                    border: none;
                    outline: none;
                    background-color: $white;

                    .arrow {
                        width: 100%;
                        margin: auto;
                        opacity: 1;
                        transition: all 0.25s ease-in-out;
                        will-change: auto;
                        &.hidden {
                            opacity: 0;
                        }
                        &.prev-arrow {
                            transform: rotate(90deg);
                        }
                        &.next-arrow {
                            transform: rotate(-90deg);
                        }
                    }
                }
            }
            .previous-order-container {
                display: inline-flex;
                padding-top: 25px;
                padding-bottom: 45px;
                max-width: 100%;
                overflow-x: scroll;
                scroll-behavior: smooth;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        .products-list-wrapper {
            display: flex;
            flex-wrap: wrap;
            min-height: 50vh;
        }

        .product-offer-section {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            background-color: $light-blue-2;

            @media (max-width: 767px) {
                flex-direction: column-reverse;
                align-items: center;
            }
            .offer-details {
                width: 50%;

                @media (max-width: 767px) {
                    width: 100%;
                    text-align: center;
                }
                .offer-title {
                    font-size: 3.5rem;
                    font-weight: 500;
                    color: $dark-green;
                    line-height: 119%;
                    text-transform: capitalize;

                    @media (max-width: 1000px) {
                        font-size: 3rem;
                    }
                }
                .offer-subtitle {
                    font-size: 3rem;
                    font-weight: 500;
                    color: $light-blue-1;
                    line-height: 72px;

                    @media (max-width: 1000px) {
                        font-size: 2rem;
                    }
                }
                .buy-now-btn {
                    font-weight: 600;
                    font-size: 1rem;
                    border-radius: 35px;
                    padding: 15px 50px;
                    background: $primary;
                    color: $dark-green;
                    text-transform: uppercase;
                    border: none;
                    outline: none;
                }
            }
            .character-image {
                height: 100%;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
